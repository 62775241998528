<template>
  <b-container class="m-0 w-100" fluid>
    <header-slot />
    <div>
      <b-row class="mb-2" align-h="end">
        <b-col cols="2">
          <b-form-group label="Year">
            <v-select
              v-model="selectDate.year"
              :options="dateTracking.year"
              @input="onDate"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Month">
            <v-select
              v-model="selectDate.month"
              :options="dateTracking.month"
              label="text"
              @input="onDate"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2" v-if="isCeo || isSupervisor || isTeamLeader">
          <b-form-group label="Advisors">
            <v-select
              v-model="selectDate.user"
              :options="advisor"
              label="user_name"
              :reduce="(user) => user.id"
              @input="onDate"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: `${changeModule.name}-tracking-list` }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        <feather-icon icon="RefreshCcwIcon" />
        IN COURSE
      </b-nav-item>
      <b-nav-item
        :to="{ name: `${changeModule.name}-tracking-list-completed` }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        <feather-icon icon="CheckCircleIcon" />
        COMPLETED
      </b-nav-item>
      <b-nav-item
        :to="{ name: `${changeModule.name}-tracking-list-pending` }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        <feather-icon icon="ClockIcon" />
        PENDING
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="key" />
    </b-card>
  </b-container>
</template>

<script>
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import { mapGetters, mapActions, mapMutations } from "vuex";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      advisor: [],
      selectDate: {
        user: {},
        year: null,
        month: {
          value: null,
          text: "",
        },
      },
      key: 1,
    };
  },
  beforeCreate() {},
  async created() {
    const date = new Date();
    this.selectDate.year = date.getFullYear();
    this.selectDate.month = {
      value: new Date().getMonth() + 1,
      text: require("moment").months()[new Date().getMonth()],
    };
    this.selectDate.user = this.currentUser.user_id;
    this.M_SET_SELECTED_USER(this.selectDate.user);
    this.A_SELECT_DATE(this.selectDate);
    await this.allAdvisor();
    this.selectDate.user = this.advisor.filter(
      (adv) => adv.id == this.currentUser.user_id
    );
    if (this.selectDate.user.length > 0) {
      this.M_SET_SELECTED_USER(this.selectDate.user[0].id);
      this.selectDate.user = this.selectDate.user[0].id;
    }
    // this sets a default advisor, but table dont reload in first load 
    // else { 
    //   this.M_SET_SELECTED_USER(this.advisor[0].id);
    //   this.selectDate.user = this.advisor[0].id;
    // }
  },
  computed: {
    // ...mapGetters('BoostCreditTrackingList', ['G_SELECT_DATE']),
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_SELECT_DATE: "BoostCreditTrackingList/G_SELECT_DATE",
      G_SELECTED_USER: "BoostCreditTrackingList/G_SELECTED_USER",
    }),

    changeModule: {
      get() {
        // save a module here
        const modules = {
          id: this.moduleId,
          name: this.getModuleName(),
        };
        return modules;
      },
    },
    dateTracking: {
      get() {
        const date = new Date();
        const dataMonth = require("moment").months(); // return a array
        const dateSelect =
          this.selectDate.year != date.getFullYear()
            ? dataMonth.length
            : date.getMonth() + 1;

        const currentDate = {
          year: [],
          month: [],
        };

        for (let i = 2019; i <= date.getFullYear(); i++) {
          currentDate.year.push(i);
        }
        for (let i = 0; i < dateSelect; i++) {
          currentDate.month.push({
            value: i + 1,
            text: dataMonth[i],
          });
        }
        return currentDate;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async allAdvisor() {
      try {
        const params = {
          roles: "[2,3]",
          type: "1",
        };
        const data = await trackingListService.userModule(
          params,
          this.moduleId
        );
        if (data.status === 200) {
          this.advisor = data.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    onDate() {
      this.A_SELECT_DATE(this.selectDate);
      this.M_SET_SELECTED_USER(this.selectDate.user);
      this.key++;
    },
    getModuleName() {
      switch (this.moduleId) {
        case 3:
          return "business";
        case 6:
          return "credit-experts";
        case 7:
          return "boost-credit";
        case 14:
          return "bookeeping";
        case 8:
          return "tax-research";
        case 20:
          return "connection";
        default:
          return "";
      }
    },
    ...mapActions("BoostCreditTrackingList", ["A_SELECT_DATE"]),
    ...mapMutations("BoostCreditTrackingList", ["M_SET_SELECTED_USER"]),
  },
  watch: {},
};
</script>

<style scoped>
</style>
